import { render, staticRenderFns } from "./peer-group.vue?vue&type=template&id=2890a2ab"
import script from "./peer-group.vue?vue&type=script&lang=js"
export * from "./peer-group.vue?vue&type=script&lang=js"
import style0 from "./peer-group.vue?vue&type=style&index=0&id=2890a2ab&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src136335406/src/web/components/loading-spinner.vue').default,TableActions: require('/codebuild/output/src136335406/src/web/components/table-actions.vue').default})
