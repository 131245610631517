import { render, staticRenderFns } from "./staffing-history-modal.vue?vue&type=template&id=3d9050d9&scoped=true"
import script from "./staffing-history-modal.vue?vue&type=script&lang=js"
export * from "./staffing-history-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d9050d9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StaffingTable: require('/codebuild/output/src1184772751/src/web/components/staffing/staffing-table.vue').default})
