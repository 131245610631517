
import pluralize from 'pluralize';

export default {

    name: 'SearchSelect',
    props: {
        value: {
            type: [Object, Array, String],
            default: () => {
                return [];
            }
        },
        url: {
            type: String,
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        addOptions: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: () => {
                return {};
            }
        },
        placeholder: {
            type: String,
            default: 'Search...'
        },
        optionLabel: {
            type: String,
            default: 'label'
        },
        selectedOptionLabel: {
            type: String,
            default: null
        },
        // Characters until searching starts
        neededInput: {
            type: Number,
            default: 3
        },
        searchOnClick: {
            type: Boolean,
            default: false
        },
        searchOnInit: {
            type: String,
            default: null
        },
        authenticate: {
            type: Boolean,
            default: false
        },
        optionsTitle: {
            type: String,
            default: 'option'
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: true
        },
        clearable: {
            type: Boolean,
            default: true
        },
        axiosInstance: {
            type: Function,
            required: true
        },
        initialOptions: {
            type: Array,
            default: null
        },
        keepInitialOptions: {
            type: Boolean,
            default: true
        },
        debounceTime: {
            type: Number,
            default: 250
        },
        additionalConditionalClasses: {
            type: Object,
            default: null
        },
        deepSearch: {
            type: Boolean,
            default: true
        },
        parseResults: {
            type: Function,
            default: (data) => {
                // api plattform support
                if (data['hydra:member']) {
                    return data['hydra:member'];
                } else {
                    return data;
                }
            }
        },
        reducer: {
            type: Function,
            default: x => x
        },
        /**
         * Array of objects. The 'icon' attribute of the object is required and has to be either be an array
         * (for example ['far', 'copy']) or a string (for example 'times'). The 'class' attribute is optional and has to
         * be a string. The 'condition' attribute is also optional and has to be a function with the select option
         * object as the only parameter and a truthy value as the return value. This attribute determines whether the
         * icon is shown for the specific option.
         */
        icons: {
            type: Array,
            required: false,
            default: null
        }
    },

    data() {
        return {
            results: [],
            content: null,
            cancelSource: null,
            search: '',
            added: false,
            loading: false
            // uuid: null,
        };
    },

    computed: {
        empty() {
            if (!this.searchable) {
                return 'No Results...';
            } else if (this.neededInput) {
                if (this.search.length >= this.neededInput) {
                    return 'No matching ' + pluralize(this.optionsTitle);
                } else if (this.addOptions) {
                    return 'Type to find ' + pluralize(this.optionsTitle) + ' or press [ENTER] to add ' + this.optionsTitle;
                } else if (this.search.length === 0) {
                    return 'Type ' + this.neededInput + ' characters to get some ' + pluralize(this.optionsTitle) + '...';
                } else {
                    return 'Type ' + (this.neededInput - this.search.length) + ' more characters to get some ' + pluralize(this.optionsTitle) + '...';
                }
            } else if (this.search) {
                return 'Type to get some ' + pluralize(this.optionsTitle);
            } else {
                return 'No matching ' + pluralize(this.optionsTitle);
            }
        },
        selectedOptionLabelFinal() {
            return this.selectedOptionLabel ? this.selectedOptionLabel : this.optionLabel;
        }
    },
    watch: {
        value: {
            handler(val) {
                // console.log("VALUE CHANGED",this.url,this.uuid,structuredClone(val))
                this.content = val;
            },
            immediate: true
        },
        initialOptions: {
            handler(val) {
                if (val) {
                    this.results = val;
                }
            },
            immediate: true

        }
    },
    created() {
        // this.uuid = this.generateUUID();

    },
    mounted() {
        if (this.searchOnInit && !this.value) {
            const ref = this.$refs.select;
            this.onSearch(this.searchOnInit, ref.toggleLoading, true).then(() => {
                if (this.results.length) {
                    this.$emit('input', this.results[0]);
                    this.content = this.results[0];
                } else {
                    this.$emit('input', null);
                    this.content = null;
                }
            });
        }
    },
    methods: {
        filterOption(options, search) {
            return options;

            // No need to also filter here, because all options have already been filtered in the backend.

            /* const strRegex = `^.*?${search}.*?$`;
            const regex = new RegExp(strRegex, 'gi');

            return options.filter((option) => {
                if (typeof option === "string" || typeof option === 'number') {
                    return ("" + option).toLowerCase().match(regex)
                }

                if (!this.deepSearch) {
                    return ("" + option[this.optionLabel]).toLowerCase().match(regex)
                }

                return Object.keys(option).filter((x) => {
                    return option[x] && (typeof option[x] === "string" || x.toString) && ("" + option[x]).toLowerCase().match(regex)
                }).length
            }); */
        },
        handleInput() {
            // console.log("INPUT",this.url,this.uuid,this.content)
            this.$emit('input', structuredClone(this.content));
        },
        onFocus() {
            if ((this.neededInput === 0 && !this.content) || this.searchOnClick) {
                const ref = this.$refs.select;
                this.onSearch(ref.search, ref.toggleLoading, true);
            }

            this.$emit('focus');
        },
        onBlur() {
            this.$emit('blur');
        },
        onOpen() {
            this.$emit('open');
        },
        onClose(el) {
            // Disable blinking cursor
            el?.querySelector('input[type=search]')?.blur();
            this.$emit('close');
        },
        objectToQueryString(obj) {
            if (!obj) {
                return '';
            }
            return Object.keys(obj).map(key => key + '=' + obj[key]).join('&');
        },
        getClassesForOption(option) {
            const classes = [];

            if (option['search-select-created-option']) {
                classes.push('option-added');
            }

            if (this.additionalConditionalClasses) {
                for (const [conditionalClass, condition] of Object.entries(this.additionalConditionalClasses)) {
                    if (condition(option)) {
                        classes.push(conditionalClass);
                    }
                }
            }

            return classes.join(' ');
        },
        getOptionLabel(option, selected = false) {
            const label = selected ? this.selectedOptionLabelFinal : this.optionLabel;

            if (option && option[label]) {
                return option[label];
            } else if (option && option.label) {
                return option.label;
            } else if (typeof option === 'string') {
                return option;
            }
            return '<placeholder label>';

        },
        onSearch(search, loading, mock = false) {
            if (!this.url) {
                const message = 'Configuration error: prop url is not set.';
                console.error(message);
            }

            return new Promise((resolve) => {

                this.search = search;

                clearTimeout(this.searchTimeout);
                this.searchTimeout = setTimeout(() => {


                    if (search.length < this.neededInput && !mock) {
                        this.results = this.keepInitialOptions && this.initialOptions ? this.initialOptions : [];
                        resolve();
                        return;
                    }

                    loading(true);

                    this.loading = true;

                    if (this.cancelSource) {
                        this.cancelSource.cancel('Operation canceled by the user.');
                    }

                    this.cancelSource = this.axiosInstance.CancelToken.source();

                    const query = this.objectToQueryString(this.params);


                    let delimitter = '?';
                    if (this.url.match(/\?./)) {
                        delimitter = '&';
                    }


                    this.axiosInstance.get(this.url + delimitter + 'term=' + search + (query ? '&' + query : ''), {
                        authenticate: this.authenticate,
                        cancelToken: this.cancelSource.token
                    }).then((response) => {

                        loading(false);
                        this.loading = false;

                        const data = this.parseResults(response.data);

                        if (!data.filter) {
                            this.results = this.keepInitialOptions && this.initialOptions ? this.initialOptions : [];
                            resolve();
                            return;
                        }

                        this.results = data.filter((option) => {
                            return !!option[this.optionLabel];
                        });

                        resolve();
                    }).catch((thrown) => {
                        if (this.axiosInstance.isCancel(thrown)) {
                            console.log('Request canceled', thrown.message);
                        } else {
                            console.log(thrown);
                        }
                    });


                }, this.debounceTime);
            });

        },
        createOption(option) {
            return { [this.optionLabel]: option, 'search-select-created-option': true };
        },
        // updateValue(value) {
        //
        //     let contains = false;
        //     for (let i = 0; i < this.results.length; i++) {
        //         if (this.results[i] && this.results[i][this.optionLabel] === value) {
        //             contains = true;
        //             break;
        //         }
        //     }
        //
        //     if (!contains && value) {
        //         this.results.unshift(value);
        //     }
        //
        //     this.$emit("input", value);
        //     this.content = value;
        // }
        getApplicableIcons(option) {
            if (!this.icons || this.icons.length === 0) {
                return [];
            }

            return this.icons.filter(icon => !icon.condition || icon.condition(option));
        }
    }

};
