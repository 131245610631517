
import LoadingOverlay from '../loading-overlay.vue';
import SaveButton from '../save-button.vue';
import ModalHeader from '../modal-header.vue';
import PressReleasesTable from './press-releases-table.vue';

export default {
    name: 'IntroAiModal',
    components: { LoadingOverlay, PressReleasesTable, SaveButton, ModalHeader },
    props: {
        company: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
        hasFreetext: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            generating: false,
            extraInstructions: '',
        };
    },
    mounted() {
        console.log('we are in the ai modal');
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            this.$emit('closed', this.item);
        });
    },
    methods: {
        submit() {
            this.generating = true;
            this.$emit('submit', {
                news: this.$refs.pressReleasesTable.preSelectedRowData,
                instructions: this.hasFreetext ? this.extraInstructions : '',
                callback: () => {
                    this.generating = false;
                },
            });
            if (this.company.businessDescriptionUpdatedBy?.contact?.firstName) {
                const toastId = this.generateUUID();
                this.addToast({
                    type: 'info',
                    title: 'Company Description found',
                    message:
                        'company description was written by ' +
                        this.company.businessDescriptionUpdatedBy?.contact?.firstName,
                    id: toastId,
                });
                this.$nextTick(() => {
                    this.toggleToast(toastId);
                });
            }
        },
    },
};
