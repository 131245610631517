import { render, staticRenderFns } from "./default.vue?vue&type=template&id=52bbbdf8&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=52bbbdf8&prod&lang=scss&scoped=true"
import style1 from "./default.vue?vue&type=style&index=1&id=52bbbdf8&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52bbbdf8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar2: require('/codebuild/output/src1184772751/src/web/components/navbar2.vue').default,Toast: require('/codebuild/output/src1184772751/src/web/components/toast.vue').default,NotificationsModal: require('/codebuild/output/src1184772751/src/web/components/notifications-modal.vue').default,EditFavoritePageModal: require('/codebuild/output/src1184772751/src/web/components/edit-favorite-page-modal.vue').default,GiveFeedbackModal: require('/codebuild/output/src1184772751/src/web/components/give-feedback-modal.vue').default})
