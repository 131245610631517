
import moment from 'moment';
import SaveButton from '../save-button';
import modals from '../../mixins/modals';
import ModalHeader from '../modal-header.vue';
import iriPreparation from '~/mixins/iri-preparation';

export default {
    name: 'ContactBulkAddressModal',
    components: { ModalHeader, SaveButton },
    mixins: [iriPreparation, modals],
    props: {
        bulkContacts: {
            type: Object,
            required: true,
        },
        id: {
            type: String,
            default: 'editDetailsModal',
        },
    },
    data() {
        return {
            loading: false,
            addressData: {
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                country: '',
            },
            modalId: moment().unix(),
        };
    },
    mounted() {
        this.$refs.modal.addEventListener('show.bs.modal', () => {
            this.modalId = moment().unix();
        });
    },
    methods: {
        submitForm() {
            this.loading = true;

            this.$axios
                .post('/api/contacts/bulk_update_address', {
                    hash: this.bulkContacts.hash,
                    address1: this.addressData.address1,
                    address2: this.addressData.address2,
                    city: this.addressData.city,
                    state: this.addressData.state,
                    zip: this.addressData.zip,
                    country: this.addressData.country,
                })
                .then(() => {
                    this.loading = false;
                    this.$emit('submitted');
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
