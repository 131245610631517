
import TableFilters from "../../table-filters.vue";
import QuintableSearchInput from "../../helpers/quintable-search-input.vue";
import FancyTooltip from "../../fancy-tooltip.vue";


export default {

    name: "TargetingGroupsList",
    components: {FancyTooltip, QuintableSearchInput, TableFilters},
    mixins: [],
    props: {},
    data() {
        return {
            isVisible: false,
            config: {
                columns: [
                    {
                        headline: "Title",
                        sort: true,
                    },
                    {
                        headline: "Company",
                        sort: true,
                    },
                    {
                        headline: "Members",
                    },
                    {
                        headline: "On Company Interest List",
                    },
                    {
                        headline: "Met with"
                    },
                    {
                        headline: "Open"
                    },
                    {
                        headline: "Interested"
                    },
                    {
                        headline: "Closed"
                    },
                    {
                        headline: "Shareholders"
                    },
                    {
                        headline: "Last Change"
                    },
                    {
                        headline: "Info"
                    },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        hidden: true,
                    }
                ],
                search: true,
                pagination: 50,
                ajaxUrl: '/api/targeting_groups/list',
            },
            searchQuery: null,
            selectedFunds: [],
            selectedInvestors: [],
            selectedCompanies: [],
        }
    },
    computed: {
        filters() {
            return {
                companies: this.selectedCompanies.map(company => company.id),
                funds: this.selectedFunds.map(company => company.id),
                investors: this.selectedInvestors.map(contact => contact.id),
            }
        },
    },
    watch: {},
    methods: {
        resetFilters() {
            this.$refs.targetingGroupsTable.setSearchQuery("");
            this.selectedCompanies = [];
            this.selectedFunds = [];
            this.selectedInvestors = [];
        },
        updateSearchQuery(searchQuery) {
            this.searchQuery = searchQuery;
            this.pageChanged = true;
        },
        onPageChange() {
            this.pageChanged = true;
        },
        delete() {
            // alert("Deleting!");
        },
        mouseover(cell) {
            this.tooltip(cell, true);
            cell.lockTooltip = true;
            // don't hide the tooltip again if the user hovers and clicks immediately
            setTimeout(() => {
                cell.lockTooltip = false;
            }, 500);
        },
        tooltip(cell, show) {
            cell.showTooltip = show;
            if (show) {
                const button = document.getElementById(`creation-info-button-${cell.id}`).getBoundingClientRect();
                cell.right = window.innerWidth - (button.right + 50);
                cell.top = button.bottom + 10;
            }
        },
    }
}
