import { render, staticRenderFns } from "./contact-group-bulk-operation-modal.vue?vue&type=template&id=0a6b7a28&scoped=true"
import script from "./contact-group-bulk-operation-modal.vue?vue&type=script&lang=js"
export * from "./contact-group-bulk-operation-modal.vue?vue&type=script&lang=js"
import style0 from "./contact-group-bulk-operation-modal.vue?vue&type=style&index=0&id=0a6b7a28&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6b7a28",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src1243370520/src/web/components/loading-spinner.vue').default,SaveButton: require('/codebuild/output/src1243370520/src/web/components/save-button.vue').default})
