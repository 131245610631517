import { render, staticRenderFns } from "./download-select-columns-form.vue?vue&type=template&id=b9feb818&scoped=true"
import script from "./download-select-columns-form.vue?vue&type=script&lang=js"
export * from "./download-select-columns-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9feb818",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HintText: require('/codebuild/output/src1243370520/src/web/components/hint-text.vue').default})
