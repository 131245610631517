import { render, staticRenderFns } from "./company-groups-table.vue?vue&type=template&id=7eb0cd64&scoped=true"
import script from "./company-groups-table.vue?vue&type=script&lang=js"
export * from "./company-groups-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7eb0cd64",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src1184772751/src/web/components/table-filters.vue').default,FavStar: require('/codebuild/output/src1184772751/src/web/components/fav-star.vue').default,TableActions: require('/codebuild/output/src1184772751/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src1184772751/src/web/components/confirm-modal.vue').default,HistoryModal: require('/codebuild/output/src1184772751/src/web/components/history-modal.vue').default,DownloadSelectColumnsModal: require('/codebuild/output/src1184772751/src/web/components/download-select-columns-modal.vue').default,CompanyGroupBulkOperationModal: require('/codebuild/output/src1184772751/src/web/components/company/company-group-bulk-operation-modal.vue').default,CompanyBulkOperationModal: require('/codebuild/output/src1184772751/src/web/components/company/company-bulk-operation-modal.vue').default})
