import { render, staticRenderFns } from "./FormulateInputDatetime.vue?vue&type=template&id=66caa13c&scoped=true"
import script from "./FormulateInputDatetime.vue?vue&type=script&lang=js"
export * from "./FormulateInputDatetime.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66caa13c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatetimeInput: require('/codebuild/output/src136335406/src/web/components/datetime-input.vue').default})
