import { render, staticRenderFns } from "./accounts.vue?vue&type=template&id=dcca3990&scoped=true"
import script from "./accounts.vue?vue&type=script&lang=js"
export * from "./accounts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcca3990",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src1184772751/src/web/components/loading-spinner.vue').default})
