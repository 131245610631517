import { render, staticRenderFns } from "./company-groups.vue?vue&type=template&id=097638b3&scoped=true"
import script from "./company-groups.vue?vue&type=script&lang=js"
export * from "./company-groups.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097638b3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src1243370520/src/web/components/loading-spinner.vue').default})
