import { render, staticRenderFns } from "./edit-note-modal.vue?vue&type=template&id=530be6de&scoped=true"
import script from "./edit-note-modal.vue?vue&type=script&lang=js"
export * from "./edit-note-modal.vue?vue&type=script&lang=js"
import style0 from "./edit-note-modal.vue?vue&type=style&index=0&id=530be6de&prod&lang=css"
import style1 from "./edit-note-modal.vue?vue&type=style&index=1&id=530be6de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530be6de",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditingHistory: require('/codebuild/output/src1243370520/src/web/components/editing-history.vue').default,SaveButton: require('/codebuild/output/src1243370520/src/web/components/save-button.vue').default})
