import { render, staticRenderFns } from "./call-log-card.vue?vue&type=template&id=3ae82a59&scoped=true"
import script from "./call-log-card.vue?vue&type=script&lang=js"
export * from "./call-log-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae82a59",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CallLogTable: require('/codebuild/output/src136335406/src/web/components/call-log/call-log-table.vue').default})
