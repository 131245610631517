import { render, staticRenderFns } from "./contact-bulk-address-modal.vue?vue&type=template&id=48cb25f6"
import script from "./contact-bulk-address-modal.vue?vue&type=script&lang=js"
export * from "./contact-bulk-address-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalHeader: require('/codebuild/output/src1243370520/src/web/components/modal-header.vue').default,SaveButton: require('/codebuild/output/src1243370520/src/web/components/save-button.vue').default})
