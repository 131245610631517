import { render, staticRenderFns } from "./download-select-columns-modal.vue?vue&type=template&id=103bd0df&scoped=true"
import script from "./download-select-columns-modal.vue?vue&type=script&lang=js"
export * from "./download-select-columns-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "103bd0df",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src136335406/src/web/components/loading-spinner.vue').default,SaveButton: require('/codebuild/output/src136335406/src/web/components/save-button.vue').default})
