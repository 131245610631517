import { render, staticRenderFns } from "./add-targeting-group-modal.vue?vue&type=template&id=5b25a81e&scoped=true"
import script from "./add-targeting-group-modal.vue?vue&type=script&lang=js"
export * from "./add-targeting-group-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b25a81e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src1243370520/src/web/components/save-button.vue').default})
