import { render, staticRenderFns } from "./sec-filings-card.vue?vue&type=template&id=7e0b742b&scoped=true"
import script from "./sec-filings-card.vue?vue&type=script&lang=js"
export * from "./sec-filings-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0b742b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableActions: require('/codebuild/output/src1243370520/src/web/components/table-actions.vue').default,LoadingSpinner: require('/codebuild/output/src1243370520/src/web/components/loading-spinner.vue').default})
