import { render, staticRenderFns } from "./manage-editors-modal.vue?vue&type=template&id=b49cbd50&scoped=true"
import script from "./manage-editors-modal.vue?vue&type=script&lang=js"
export * from "./manage-editors-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b49cbd50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src1184772751/src/web/components/save-button.vue').default})
