import { render, staticRenderFns } from "./investor-targets-table.vue?vue&type=template&id=111b87d6&scoped=true"
import script from "./investor-targets-table.vue?vue&type=script&lang=js"
export * from "./investor-targets-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111b87d6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src1184772751/src/web/components/table-filters.vue').default,DownloadSelectColumnsModal: require('/codebuild/output/src1184772751/src/web/components/download-select-columns-modal.vue').default})
