
import modals from '../../../mixins/modals';
import loadWhenVisible from '../../../mixins/loadWhenVisible';
import optionButtonTooltip from '../../../mixins/option-button-tooltip';
import IntroAiModal from '../intro-ai-modal.vue';
import Milestones from './milestones';
import Projections from './projections';
import CompanySheetsDownloadModal from './company-sheets-download-modal';
import CompanySheetBookModal from './company-sheet-book-modal.vue';

export default {
    name: 'AnalystDataCard',
    components: {
        IntroAiModal,
        CompanySheetBookModal,
        Projections,
        Milestones,
        CompanySheetsDownloadModal,
    },
    mixins: [modals, loadWhenVisible, optionButtonTooltip],
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            drivers: [],
            risks: [],
            revisionDate: null,
            revisionDateEditEnabled: false,
            investmentSummary: null,
            investSummaryPreviousValue: null,
            investSummaryEditEnabled: false,
            newDriver: '',
            newRisk: '',
            loading: false,
            milestones: [],
            projections: [],
            modal: null,
        };
    },
    watch: {
        loadDataTrigger(value) {
            if (value) {
                this.loadAnalystData();
            }
        },
    },
    methods: {
        addItem(type, value) {
            this.loading = true;
            const data = {};
            data[type] = value;
            this.$axios
                .post('/api/analyst_data/add_point/' + this.company.id, data)
                .then((response) => {
                    this.newDriver = '';
                    this.newRisk = '';
                })
                .finally(() => {
                    this.loading = false;
                    this.loadAnalystData();
                });
        },
        deleteItem(id) {
            this.loading = true;
            this.$axios.delete('/api/analyst_data_points/' + id).finally(() => {
                this.loading = false;
                this.loadAnalystData();
            });
        },
        editInvestSummary() {
            if (!this.loading && this.isUserWithRoleResearchAnalyst) {
                this.investSummaryPreviousValue = this.investmentSummary;
                this.investSummaryEditEnabled = true;
            }
        },
        cancelInvestSummaryEdit() {
            this.investSummaryEditEnabled = false;
            this.investmentSummary = this.investSummaryPreviousValue;
            this.investSummaryPreviousValue = null;
        },
        updateInvestSummary() {
            this.loading = true;
            this.$axios
                .post('/api/analyst_data/investment_summary/' + this.company.id, {
                    investmentSummary: this.investmentSummary,
                })
                .finally(() => {
                    this.loadAnalystData();
                    this.loading = false;
                });
            this.investSummaryEditEnabled = false;
            this.investSummaryPreviousValue = null;
        },
        editRevisionDate() {
            if (!this.loading && this.isUserWithRoleResearchAnalyst) {
                this.revisionDateEditEnabled = true;
            }
        },
        cancelRevisionDateEdit() {
            this.revisionDateEditEnabled = false;
        },
        updateRevisionDate() {
            this.loading = true;
            this.$axios
                .post('/api/analyst_data/revision_date/' + this.company.id, {
                    revisionDate: this.revisionDate,
                })
                .finally(() => {
                    this.loadAnalystData();
                    this.loading = false;
                });
            this.revisionDateEditEnabled = false;
        },
        loadAnalystData() {
            this.loading = true;
            this.$axios
                .get('/api/analyst_data/prepared/' + this.company.id)
                .then((response) => {
                    this.drivers = response.data.drivers;
                    this.risks = response.data.risks;
                    this.investmentSummary = response.data.investmentSummary
                        ? response.data.investmentSummary.data
                        : '';
                    this.revisionDate = response.data.revisionDate ? response.data.revisionDate.data : '';
                    this.milestones = response.data.companyMilestones;
                    this.projections = response.data.companyProjections;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openDownloadModal() {
            this.openModal(this.$refs.companySheetsDownloadModal);
        },
        async openAiModal() {
            // add modal to DOM
            this.modal = true;
            await this.$nextTick();

            // open modal
            this.modal = this.openModal(this.$refs.introAiModal);
        },
        onAiModalClose() {
            this.modal = null;
        },
        async openSheetBookModal() {
            this.$refs.companySheetBookModal.reset();
            await this.$nextTick();
            this.openModal(this.$refs.companySheetBookModal);
        },
        submitAiModal({ news, instructions, callback }) {
            this.$axios
                .post('/api/analyst_data/generate_ai_summary/' + this.company.id, { news, instructions })
                .then((res) => {
                    const toastId = this.generateUUID();

                    if (!res.data.success) {
                        this.addToast({
                            type: 'error',
                            title: 'Generating failed',
                            message: 'There was an error generating AI summary.',
                            id: toastId,
                        });
                    } else {
                        this.addToast({
                            type: 'success',
                            title: 'Generating successful',
                            message: "AI Summary generated successfully. Please don't forget to review it.",
                            id: toastId,
                        });
                        this.investSummaryPreviousValue = this.investmentSummary;
                        this.investmentSummary = res.data.text;
                        this.investSummaryEditEnabled = true;
                        this.closeModal(this.modal);
                    }

                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    });
                })
                .finally(() => {
                    callback?.();
                });
        },
    },
};
