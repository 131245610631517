import { render, staticRenderFns } from "./research-reports-table.vue?vue&type=template&id=8e666d4c"
import script from "./research-reports-table.vue?vue&type=script&lang=js"
export * from "./research-reports-table.vue?vue&type=script&lang=js"
import style0 from "./research-reports-table.vue?vue&type=style&index=0&id=8e666d4c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src136335406/src/web/components/table-filters.vue').default,HintText: require('/codebuild/output/src136335406/src/web/components/hint-text.vue').default,TableActions: require('/codebuild/output/src136335406/src/web/components/table-actions.vue').default,ResearchReportReadershipModal: require('/codebuild/output/src136335406/src/web/components/research-reports/research-report-readership-modal.vue').default})
