import { render, staticRenderFns } from "./redraft-contract-modal.vue?vue&type=template&id=250fbb9c&scoped=true"
import script from "./redraft-contract-modal.vue?vue&type=script&lang=js"
export * from "./redraft-contract-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "250fbb9c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src1184772751/src/web/components/save-button.vue').default})
