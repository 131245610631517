import { render, staticRenderFns } from "./notifications-modal.vue?vue&type=template&id=558059be"
import script from "./notifications-modal.vue?vue&type=script&lang=js"
export * from "./notifications-modal.vue?vue&type=script&lang=js"
import style0 from "./notifications-modal.vue?vue&type=style&index=0&id=558059be&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src1184772751/src/web/components/loading-spinner.vue').default,TableFilters: require('/codebuild/output/src1184772751/src/web/components/table-filters.vue').default})
