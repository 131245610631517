import { render, staticRenderFns } from "./contact-name-cell.vue?vue&type=template&id=64b5b3bc&scoped=true"
import script from "./contact-name-cell.vue?vue&type=script&lang=js"
export * from "./contact-name-cell.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b5b3bc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RedFlag: require('/codebuild/output/src1184772751/src/web/components/red-flag.vue').default})
