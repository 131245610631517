import { render, staticRenderFns } from "./note.vue?vue&type=template&id=191fa44f&scoped=true"
import script from "./note.vue?vue&type=script&lang=js"
export * from "./note.vue?vue&type=script&lang=js"
import style0 from "./note.vue?vue&type=style&index=0&id=191fa44f&prod&scoped=true&lang=scss"
import style1 from "./note.vue?vue&type=style&index=1&id=191fa44f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191fa44f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PCheckIcon: require('/codebuild/output/src1243370520/src/web/components/p-check-icon.vue').default})
