import { render, staticRenderFns } from "./event-details-card.vue?vue&type=template&id=e5b3739e&scoped=true"
import script from "./event-details-card.vue?vue&type=script&lang=js"
export * from "./event-details-card.vue?vue&type=script&lang=js"
import style0 from "./event-details-card.vue?vue&type=style&index=0&id=e5b3739e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5b3739e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SaveButton: require('/codebuild/output/src1184772751/src/web/components/save-button.vue').default,GmapsAddress: require('/codebuild/output/src1184772751/src/web/components/gmaps-address.vue').default,ConfirmModal: require('/codebuild/output/src1184772751/src/web/components/confirm-modal.vue').default})
