import { render, staticRenderFns } from "./edit-details-modal.vue?vue&type=template&id=3b772eca&scoped=true"
import script from "./edit-details-modal.vue?vue&type=script&lang=js"
export * from "./edit-details-modal.vue?vue&type=script&lang=js"
import style0 from "./edit-details-modal.vue?vue&type=style&index=0&id=3b772eca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b772eca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompanyForm: require('/codebuild/output/src136335406/src/web/components/company/company-form.vue').default,SaveButton: require('/codebuild/output/src136335406/src/web/components/save-button.vue').default})
