import { render, staticRenderFns } from "./staffing-table.vue?vue&type=template&id=d6044eda"
import script from "./staffing-table.vue?vue&type=script&lang=js"
export * from "./staffing-table.vue?vue&type=script&lang=js"
import style0 from "./staffing-table.vue?vue&type=style&index=0&id=d6044eda&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src136335406/src/web/components/loading-spinner.vue').default,TableActions: require('/codebuild/output/src136335406/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src136335406/src/web/components/confirm-modal.vue').default})
