import { render, staticRenderFns } from "./navbar2.vue?vue&type=template&id=94869bd8&scoped=true"
import script from "./navbar2.vue?vue&type=script&lang=js"
export * from "./navbar2.vue?vue&type=script&lang=js"
import style0 from "./navbar2.vue?vue&type=style&index=0&id=94869bd8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94869bd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchBox: require('/codebuild/output/src1243370520/src/web/components/search-box.vue').default,Subnavs: require('/codebuild/output/src1243370520/src/web/components/subnavs.vue').default})
