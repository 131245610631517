import { render, staticRenderFns } from "./edit.vue?vue&type=template&id=064cb25a&scoped=true"
import script from "./edit.vue?vue&type=script&lang=js"
export * from "./edit.vue?vue&type=script&lang=js"
import style0 from "./edit.vue?vue&type=style&index=0&id=064cb25a&prod&scoped=true&lang=scss"
import style1 from "./edit.vue?vue&type=style&index=1&id=064cb25a&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064cb25a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HintText: require('/codebuild/output/src1184772751/src/web/components/hint-text.vue').default,UploadDropZone: require('/codebuild/output/src1184772751/src/web/components/upload-drop-zone.vue').default,SaveButton: require('/codebuild/output/src1184772751/src/web/components/save-button.vue').default,LoadingSpinner: require('/codebuild/output/src1184772751/src/web/components/loading-spinner.vue').default,ConfirmModal: require('/codebuild/output/src1184772751/src/web/components/confirm-modal.vue').default})
