import { render, staticRenderFns } from "./transactions-table.vue?vue&type=template&id=25dd3364"
import script from "./transactions-table.vue?vue&type=script&lang=js"
export * from "./transactions-table.vue?vue&type=script&lang=js"
import style0 from "./transactions-table.vue?vue&type=style&index=0&id=25dd3364&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src136335406/src/web/components/table-filters.vue').default,TableActions: require('/codebuild/output/src136335406/src/web/components/table-actions.vue').default})
