import { render, staticRenderFns } from "./research-report-readership-modal.vue?vue&type=template&id=4644bdbe&scoped=true"
import script from "./research-report-readership-modal.vue?vue&type=script&lang=js"
export * from "./research-report-readership-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4644bdbe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResearchReportReadershipTable: require('/codebuild/output/src1243370520/src/web/components/research-reports/research-report-readership-table.vue').default})
