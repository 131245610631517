import { render, staticRenderFns } from "./peers-list.vue?vue&type=template&id=614a380f"
import script from "./peers-list.vue?vue&type=script&lang=js"
export * from "./peers-list.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchSelect: require('/codebuild/output/src136335406/src/web/components/search-select.vue').default,TableActions: require('/codebuild/output/src136335406/src/web/components/table-actions.vue').default})
