import { render, staticRenderFns } from "./interest-list-bulk-operation-modal.vue?vue&type=template&id=58b2bcfe&scoped=true"
import script from "./interest-list-bulk-operation-modal.vue?vue&type=script&lang=js"
export * from "./interest-list-bulk-operation-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b2bcfe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src136335406/src/web/components/loading-spinner.vue').default,CompanyBulkOperationForm: require('/codebuild/output/src136335406/src/web/components/company/company-bulk-operation-form.vue').default,SaveButton: require('/codebuild/output/src136335406/src/web/components/save-button.vue').default})
