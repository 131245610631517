
import moment from 'moment';
import { OPTION_COMPANY } from '../../mixins/company-classification';

export default {
    name: 'ATMOTable',
    components: {},
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tableUpdated: moment().unix(),
            config: {
                ajaxUrl: '/api/companies/atmOfferings/' + this.company.id,
                // hideEmptyColumns: true,
                columns: [
                    {
                        headline: 'Announcement Date',
                        sort: false,
                    },
                    {
                        headline: 'Company',
                        sort: false,
                        hidden: this.company.classification === OPTION_COMPANY,
                    },
                    {
                        headline: 'Agents',
                        sort: false,
                    },
                    {
                        headline: 'Size',
                        sort: false,
                    },
                ],
                pagination: 10,
            },
        };
    },
};
