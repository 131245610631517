import { render, staticRenderFns } from "./company-form.vue?vue&type=template&id=78ac37ea&scoped=true"
import script from "./company-form.vue?vue&type=script&lang=js"
export * from "./company-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78ac37ea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FileUploadInput: require('/codebuild/output/src1184772751/src/web/components/file-upload-input.vue').default,LoadingSpinner: require('/codebuild/output/src1184772751/src/web/components/loading-spinner.vue').default,Suggestion: require('/codebuild/output/src1184772751/src/web/components/suggestion.vue').default,HintText: require('/codebuild/output/src1184772751/src/web/components/hint-text.vue').default})
