
import DownloadSelectColumnsForm from '../company/download-select-columns-form.vue';

export default {
    name: "InteractionDownloadModal",
    components: { DownloadSelectColumnsForm },
    data() {
        return {
            from: null,
            to: null
        }
    },
    computed: {
        query() {
            return {
                filters: {
                    userId: this.userInfo.id,
                    minStartDate: this.from,
                    maxStartDate: this.to,
                    hideEmails: true,
                },
            }
        }
    },
}
