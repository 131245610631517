import { render, staticRenderFns } from "./service-providers-card.vue?vue&type=template&id=26817dde&scoped=true"
import script from "./service-providers-card.vue?vue&type=script&lang=js"
export * from "./service-providers-card.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26817dde",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableActions: require('/codebuild/output/src1184772751/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src1184772751/src/web/components/confirm-modal.vue').default})
