import { render, staticRenderFns } from "./files-card.vue?vue&type=template&id=72c17a2c&scoped=true"
import script from "./files-card.vue?vue&type=script&lang=js"
export * from "./files-card.vue?vue&type=script&lang=js"
import style0 from "./files-card.vue?vue&type=style&index=0&id=72c17a2c&prod&scoped=true&lang=scss"
import style1 from "./files-card.vue?vue&type=style&index=1&id=72c17a2c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c17a2c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src1243370520/src/web/components/loading-spinner.vue').default,SkeletonFiles: require('/codebuild/output/src1243370520/src/web/components/skeleton/skeleton-files.vue').default,TableActions: require('/codebuild/output/src1243370520/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src1243370520/src/web/components/confirm-modal.vue').default})
