
export default {
    name: 'PercentageChange',
    props: {
        number: {
            type: [Number, String],
            default: null,
        },
    },
};
