import { render, staticRenderFns } from "./recipient-details-modal.vue?vue&type=template&id=647fc937&scoped=true"
import script from "./recipient-details-modal.vue?vue&type=script&lang=js"
export * from "./recipient-details-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647fc937",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src1184772751/src/web/components/table-filters.vue').default})
