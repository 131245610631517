import { render, staticRenderFns } from "./other-sa-news.vue?vue&type=template&id=1b366bd8&scoped=true"
import script from "./other-sa-news.vue?vue&type=script&lang=js"
export * from "./other-sa-news.vue?vue&type=script&lang=js"
import style0 from "./other-sa-news.vue?vue&type=style&index=0&id=1b366bd8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b366bd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StockInfoSmall: require('/codebuild/output/src136335406/src/web/components/stock-info-small.vue').default,SaveButton: require('/codebuild/output/src136335406/src/web/components/save-button.vue').default})
