import { render, staticRenderFns } from "./contact-event-participation.vue?vue&type=template&id=65987376&scoped=true"
import script from "./contact-event-participation.vue?vue&type=script&lang=js"
export * from "./contact-event-participation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65987376",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EventsTable: require('/codebuild/output/src1184772751/src/web/components/events/events-table.vue').default})
