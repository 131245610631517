import { render, staticRenderFns } from "./sa-news-table.vue?vue&type=template&id=29fc99ee&scoped=true"
import script from "./sa-news-table.vue?vue&type=script&lang=js"
export * from "./sa-news-table.vue?vue&type=script&lang=js"
import style0 from "./sa-news-table.vue?vue&type=style&index=0&id=29fc99ee&prod&scoped=true&lang=css"
import style1 from "./sa-news-table.vue?vue&type=style&index=1&id=29fc99ee&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29fc99ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src1243370520/src/web/components/table-filters.vue').default,StockInfoSmall: require('/codebuild/output/src1243370520/src/web/components/stock-info-small.vue').default})
