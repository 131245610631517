
import moment from "moment";
import ConfirmModal from "../confirm-modal";
import TableActions from "../table-actions";
import modals from "../../mixins/modals";
import loadWhenVisible from "../../mixins/loadWhenVisible";
import fileDownload from "../../mixins/file-download";
import EditServiceProviderModal from "./edit-service-provider-modal";
import optionButtonTooltip from "~/mixins/option-button-tooltip";

export default {
    name: "ServiceProvidersCard",
    components: {TableActions, ConfirmModal, EditServiceProviderModal},
    mixins: [optionButtonTooltip, modals, loadWhenVisible, fileDownload],
    props: {
        company: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            config: {
                columns: [
                    {
                        headline: "Icon",
                        hideHeadlineBreakpoint: 'all',
                    },
                    {
                        headline: "Company",
                    }, {
                        headline: "Role",
                    }, {
                        headline: "Confirmed",
                        align: "center"
                    }, {
                        headline: "Description",
                        breakpoint: 'all',
                    }, {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: 'end'
                    }
                ],
                pagination: 5,
                ajaxUrl: "",
            },
            tableUpdate: new Date(),
            serviceProviderTemplate: {},
            serviceProviderToEdit: {},
            idToDelete: null,
            rowsUpdated: moment().unix(),
        }
    },
    computed: {},
    watch: {
        rowsUpdated() {
            setTimeout(() => this.initializeOptionButtonTooltips('rows updated'), 250);
        },
        loadDataTrigger(value) {
            if (value) {
                this.tableUpdate = new Date();
            }
        },
    },
    mounted() {
        this.config.ajaxUrl = "/api/service_providers/list_both/" + this.company.id;
        this.tableUpdate = new Date();
    },
    methods: {
        serviceProvidersUpdated() {
            // this.$emit('company-updated');
            this.tableUpdate = new Date();
            this.closeModal(this.modal);
        },
        addServiceProvider() {
            this.$axios.get("/api/service_providers/empty/" + this.company.id)
                .then((response) => {
                    this.serviceProviderTemplate = response.data;
                    this.serviceProviderToEdit = Object.assign({}, this.serviceProviderTemplate);
                    this.openEditModal();
                });
        },
        openEditModal() {
            this.modal = this.openModal(this.$refs.editServiceProviderModal);
        },
        editServiceProvider(id) {
            this.$axios.get("/api/service_providers/" + id)
                .then((response) => {
                    this.serviceProviderToEdit = response.data;
                    this.openEditModal();
                });
        },
        deleteServiceProvider() {
            this.$axios.delete("/api/service_providers/" + this.idToDelete)
                .then((response) => {
                    const toastId = this.generateUUID();
                    this.addToast({
                        type: "success",
                        title: "Success",
                        message: "The service provider has been deleted!",
                        id: toastId,
                    });
                    this.$nextTick(() => {
                        this.toggleToast(toastId);
                    })
                }).finally(() => {
                    this.tableUpdate = new Date();
                });
        },
        preDeleteServiceProvider(id) {
            this.idToDelete = id;
            this.modal = this.openModal(this.$refs.deleteServiceProviderModal);
        },
        cancelDeleteServiceProvider() {
            this.$nextTick(() => {
                this.idToDelete = null;
            })
        },
        tableUpdateHandler() {
            this.rowsUpdated = moment().unix();
        },
        updateConfirmed(id) {
            this.$axios.post("/api/service_providers/confirm/" + id)
                .then((response) => {
                    this.tableUpdate = new Date();
                });
            const toastId = this.generateUUID();
            this.addToast({
                type: "success",
                title: "Success",
                message: "The service provider has been confirmed!",
                id: toastId,
            });
            this.$nextTick(() => {
                this.toggleToast(toastId);
            })
        },
        downloadExcel() {
            const query = {
                filters: {
                    company: this.company.id
                }
            };
            this.saveQueryAndExport(query, '/api/service_providers/export');
        },
    }
}
